
import { useState } from 'react';
import './projetos.css';
import Title from '../../components/Title';
import Header from '../../components/Header';
import firebase from '../../services/firebaseConnection';
import { FiUser } from 'react-icons/fi';

import { toast } from 'react-toastify';

export default function Customers(){
  const [nomesecao, setNomesecao] = useState('');


  async function handleAdd(e){
    e.preventDefault();
    
    if(nomesecao !== ''){
      await firebase.firestore().collection('secao')
      .add({
        nomesecao: nomesecao,
      })
      .then(()=>{
        setNomesecao('');
        toast.info('Seção cadastrada com sucesso!');
      })
      .catch((error)=>{
        console.log(error);
        toast.error('Erro ao cadastrar Seção.');
      })
    }else{
      toast.error('Preencha todos os campos!')
    }

  }

  return(
    <div>
      <Header/>

    <div className="content">
      <Title name="Seção">
        <FiUser size={25} />
      </Title>

      <div className="container">
        <form className="form-profile customers" onSubmit={handleAdd}>
          <label>Nome Seção</label>
          <input type="text" placeholder="Seção" value={nomesecao} onChange={ (e) => setNomesecao(e.target.value) } />

          <button type="submit">Cadastrar</button>

        </form>
      </div>

    </div>

    </div>
  )
}