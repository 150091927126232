
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './pontos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadcidades, setLoadcidades] = useState(true);
  const [cidades, setCidades] = useState([]);
  const [cidadesSelected, setCidadesSelected] = useState(0);

  const [nomeponto, setNomeponto] = useState('');
  const [cidadeponto, setCidadeponto] = useState('');
  const [categoriaponto, setCategoriaponto] = useState('');
  const [textosimplesponto, setTextosimplesponto] = useState('');
  const [logradouroponto, setLogradouroponto] = useState('');
  const [numeroponto, setNumeroponto] = useState('');
  const [complementoponto, setComplementoponto] = useState('');
  const [gpsponto, setGpsponto] = useState('');

  const [idcidades, setIdcidades] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadcidades(){
      await firebase.firestore().collection('cidades')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomecidade: doc.data().nomecidade
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setCidades([ { id: '1', nomecidade: 'AVULSO' } ]);
          setLoadcidades(false);
          return;
        }

        setCidades(lista);
        setLoadcidades(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadcidades(false);
        setCidades([ { id: '1', nomecidade: '' } ]);
      })

    }
    loadcidades();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('pontosinteresse').doc(id)
    .get()
    .then((snapshot) => {
      setNomeponto(snapshot.data().nomeponto);
      setCidadeponto(snapshot.data().cidadeponto);
      setCategoriaponto(snapshot.data().categoriaponto);
      setTextosimplesponto(snapshot.data().textosimplesponto);
      setLogradouroponto(snapshot.data().logradouroponto);
      setNumeroponto(snapshot.data().numeroponto);
      setComplementoponto(snapshot.data().complementoponto);
      setGpsponto(snapshot.data().gpsponto);

      let index = lista.findIndex(item => item.id === snapshot.data().cidadeId );
      setCidadesSelected(index);
      setIdcidades(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdcidades(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idcidades){
      await firebase.firestore().collection('pontosinteresse')
      .doc(id)
      .update({
        nomeponto: nomeponto,
        cidadeponto: cidades[cidadesSelected].nomecidade,
        cidadeId: cidades[cidadesSelected].id,
        categoriaponto: categoriaponto,
        textosimplesponto: textosimplesponto,
        logradouroponto: logradouroponto,
        numeroponto:numeroponto,
        complementoponto: complementoponto,
        gpsponto: gpsponto,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Ponto editado com sucesso!');
        setCidadesSelected(0);
        setNomeponto('');
        setCidadeponto('');
        setCategoriaponto('');
        setTextosimplesponto('');
        setLogradouroponto('');
        setNumeroponto('');
        setComplementoponto('');
        setGpsponto('');
        setCidadesSelected(0);
        history.push('/pontosdeinteresselistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('pontosinteresse')
    .add({
      created: new Date(),
      nomeponto: nomeponto,
      cidadeponto: cidadeponto,
      categoriaponto: categoriaponto,
      textosimplesponto: textosimplesponto,
      logradouroponto: logradouroponto,
      numeroponto:numeroponto,
      complementoponto: complementoponto,
      gpsponto: gpsponto, 
      userId: user.uid
    })
    .then(()=> {
      toast.success('Ponto de interesse cadastrado com sucesso!');
      setNomeponto('');
      setCidadeponto('');
      setCategoriaponto('');
      setTextosimplesponto('');
      setLogradouroponto('');
      setNumeroponto('');
      setComplementoponto('');
      setGpsponto('');
      setCidadesSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }

 //Chamado quando troca a cidade
  function handleChangeCidade(e){
    setCidadesSelected(e.target.value);
  }


  //Chamado quando troca a categoria
  function handleChangeCategoria(e){
    setCategoriaponto(e.target.value);
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Novo Ponto Interesse">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >


            <label>Nome</label>
            <input type="text" placeholder="Nome do ponto" value={nomeponto} onChange={ (e) => setNomeponto(e.target.value) } />

            <label>Cidade</label>
            {loadcidades ? (
              <input type="text" disabled={true} value="Carregando cidades..." />
            ) : (
                <select value={cidadesSelected} onChange={handleChangeCidade} >
                {cidades.map((item, index) => {
                  return(
                    <option key={item.id} value={index} >
                      {item.nomecidade}
                    </option>
                  )
                })}
              </select>
            )}

            <label>Categoria</label>
            <select value={categoriaponto} onChange={handleChangeCategoria}>
              <option value="Atrativo">Atrativo</option>
              <option value="Alimentação">Alimentação</option>
              <option value="Hospedagem">Hospedagem</option>
            </select>


            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesponto}
              onChange={ (e) => setTextosimplesponto(e.target.value) }
            />

            <label>Logradouro</label>
            <input type="text" placeholder="Logradouro" value={logradouroponto} onChange={ (e) => setLogradouroponto(e.target.value) } />

            <label>Número</label>
            <input type="text" placeholder="Número" value={numeroponto} onChange={ (e) => setNumeroponto(e.target.value) } />

            <label>Complemento</label>
            <input type="text" placeholder="Complemento" value={complementoponto} onChange={ (e) => setComplementoponto(e.target.value) } />

            <label>GPS</label>
            <input type="text" placeholder="GPS" value={gpsponto} onChange={ (e) => setGpsponto(e.target.value) } />

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}