
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './projetos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadProjetos, setLoadProjetos] = useState(true);
  const [projetos, setProjetos] = useState([]);
  const [projetosSelected, setProjetosSelected] = useState(0);

  const [nomeprojeto, setNomeprojeto] = useState('');
  const [textosimplesprojeto, setTextosimplesprojeto] = useState('');
  const [textocompletoprojeto, setTextocompletoprojeto] = useState('');
  const [linkfbprojeto, setLinkfbprojeto] = useState('');
  const [linkinstagramprojeto, setLinkinstagramprojeto] = useState('');
  const [linksiteprojeto, setLinksiteprojeto] = useState('');

  const [idprojetos, setIdprojetos] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadProjetos(){
      await firebase.firestore().collection('projetos')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeprojeto: doc.data().nomeprojeto
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA PROJETO ENCONTRADA');
          setProjetos([ { id: '1', nomeprojeto: 'AVULSO' } ]);
          setLoadProjetos(false);
          return;
        }

        setProjetos(lista);
        setLoadProjetos(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadProjetos(false);
        setProjetos([ { id: '1', nomeprojeto: '' } ]);
      })

    }
    loadProjetos();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('projetos').doc(id)
    .get()
    .then((snapshot) => {
      setNomeprojeto(snapshot.data().nomeprojeto);
      setTextosimplesprojeto(snapshot.data().textosimplesprojeto);
      setTextocompletoprojeto(snapshot.data().textocompletoprojeto);
      setLinkfbprojeto(snapshot.data().linkfbprojeto);
      setLinkinstagramprojeto(snapshot.data().linkinstagramprojeto);
      setLinksiteprojeto(snapshot.data().linksiteprojeto)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setProjetosSelected(index);
      setIdprojetos(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdprojetos(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idprojetos){
      await firebase.firestore().collection('projetos')
      .doc(id)
      .update({
        nomeprojeto: nomeprojeto,
        textosimplesprojeto: textosimplesprojeto,
        textocompletoprojeto: textocompletoprojeto,
        linkfbprojeto: linkfbprojeto,
        linkinstagramprojeto: linkinstagramprojeto,
        linksiteprojeto: linksiteprojeto,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Projeto editado com sucesso!');
        setProjetosSelected(0);
        setNomeprojeto('');
        history.push('/Projetoslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('projetos')
    .add({
      created: new Date(),
      nomeprojeto: nomeprojeto,
      textosimplesprojeto: textosimplesprojeto,
      textocompletoprojeto: textocompletoprojeto,
      linkfbprojeto: linkfbprojeto,
      linkinstagramprojeto: linkinstagramprojeto,
      linksiteprojeto: linksiteprojeto,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Projeto cadastrada com sucesso!');
      setNomeprojeto('');
      setTextosimplesprojeto('');
      setTextocompletoprojeto('');
      setLinkfbprojeto('');
      setLinkinstagramprojeto('');
      setLinksiteprojeto('');
      setProjetosSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Projetos">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Projetos</label>
            <input type="text" placeholder="Nome do projeto" value={nomeprojeto} onChange={ (e) => setNomeprojeto(e.target.value) } />

            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesprojeto}
              onChange={ (e) => setTextosimplesprojeto(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletoprojeto}
              onChange={ (e) => setTextocompletoprojeto(e.target.value) }
            />

            <label>Facebook</label>
            <input type="text" placeholder="link Facebook" value={linkfbprojeto} onChange={ (e) => setLinkfbprojeto(e.target.value) } />
            <label>Instagram</label>
            <input type="text" placeholder="link Instagram" value={linkinstagramprojeto} onChange={ (e) => setLinkinstagramprojeto(e.target.value) } />
            <label>Página</label>
            <input type="text" placeholder="link sem o http://" value={linksiteprojeto} onChange={ (e) => setLinksiteprojeto(e.target.value) } />
            
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}