
import { useState, useContext, useEffect } from 'react';
import './imagens.css';
import Title from '../../components/Title';
import Header from '../../components/Header';
import firebase from '../../services/firebaseConnection';
import { FiUser, FiPlusCircle, FiUpload} from 'react-icons/fi';
import { AuthContext } from '../../contexts/auth';

import { toast } from 'react-toastify';

import avatar from '../imagens/img.png';

export default function Imgs(){
  const [banner1, setBanner1] = useState();
  const [banner2, setBanner2] = useState();
  const [banner3, setBanner3] = useState();
  const [banner4, setBanner4] = useState();
  const [bannerapp1, setBannerapp1] = useState();
  const [bannerapp2, setBannerapp2] = useState();

  const [image, setImage] = useState(null);
  const [imageurl, setImageUrl] = useState(null);
  const [selecionalocal, setSelecionalocal] = useState('bannersup1');
  const { user } = useContext(AuthContext);

  useEffect(()=> {

    async function loadBanners(){
      await firebase.firestore().collection('imagens')
      .doc('CCBEvwSLhZ7HlcUGJRjZ')
      .get()
      .then((snapshot) => {
        setBanner1(snapshot.data().bannersup1);
        setBanner2(snapshot.data().bannersup2);
        setBanner3(snapshot.data().bannersup3);
        setBanner4(snapshot.data().bannersup4);
        setBannerapp1(snapshot.data().bannerapp1inicial);
        setBannerapp2(snapshot.data().bannerapp2inicial);
      })

    }
    loadBanners();

    return () => {

    }
  }, []);
  
  function handleChangelocal(e){
  setSelecionalocal(e.target.value);
}

function handleFile(e){
  if(e.target.files[0]){
    const image = e.target.files[0];
    
    if(image.type === 'image/jpeg' || image.type === 'image/png'){

      setImage(image);
      setImageUrl(URL.createObjectURL(e.target.files[0]))

    }else{
      alert('Envie uma imagem do tipo PNG ou JPEG');
      setImageUrl(null);
      return null;
    }
  }
}

async function handleUpload(){
  toast.success('Envio Iniciado!');
  if (image){
  const uploadTask = await firebase.storage()
  .ref(`imagens/${selecionalocal}`)
  .put(image)
  .then( async () => {
    toast.success('Envio Completo!');
    await firebase.storage().ref(`imagens/`)
    .child(`${selecionalocal}`).getDownloadURL()
    .then( async (url)=>{
      let urlDoc = url;
      toast.success(`enviou corrreto ${urlDoc}`);
      
      await firebase.firestore().collection('imagens')
      .doc('CCBEvwSLhZ7HlcUGJRjZ')
      .update({
        [selecionalocal]: urlDoc
      })
      .then(()=>{
        toast.success(`Imagem atualizada! `);
      })
    })
  })
    }
    else {
    alert('Upload failed, sorry :( 39');
  } 
};
  return(
    <div>
      <Header/>

    <div className="content">
      <Title name="Imagens">
        <FiUser size={25} />
      </Title>

      <div className="container">
        <form className="form-profile customers" onSubmit={handleUpload}>
          <div>
            <label>
             Banner Atual: <i></i>
            </label>
          </div>
          <div>
           { banner1 == null ? 
                <div>Banner 1<br></br><img src={avatar} width="250" height="100" alt="Sem Foto" /></div>
                :
                <div>Banner 1<br></br><img src={banner1} width="250" height="100" text="Banner1" /></div>
          }
           { banner2 == null ? 
                <div>Banner 2<br></br><img src={avatar} width="250" height="100" alt="Sem Foto" /></div>
                :
                <div>Banner 2<br></br><img src={banner2} width="250" height="100" text="Banner2" /></div>
          }
           { banner3 == null ? 
                <div>Banner 3<br></br><img src={avatar} width="250" height="100" alt="Sem Foto" /></div>
                :
                <div>Banner 3<br></br><img src={banner3} width="250" height="100" text="Banner3" /></div>
          }
           { banner4 == null ? 
                <div>Banner 4<br></br><img src={avatar} width="250" height="100" alt="Sem Foto" /></div>
                :
                <div>Banner 4<br></br><img src={banner4} width="250" height="100" text="Banner4" /></div>
          }
           { bannerapp1 == null ? 
                <div>Banner Aplicativo 1<br></br><img src={avatar} width="250" height="100" alt="Sem Foto" /></div>
                :
                <div>Banner Aplicativo 1<br></br><img src={bannerapp1} width="250" height="100" text="Banner Aplicativo 1" /></div>
          }
           { bannerapp2 == null ? 
                <div>Banner Aplicativo 2<br></br><img src={avatar} width="250" height="100" alt="Sem Foto" /></div>
                :
                <div>Banner Aplicativo 2<br></br><img src={bannerapp2} width="250" height="100" text="Banner Aplicativo 2" /></div>
          }
          </div>

            <label>
             SELECIONE O LOCAL DA IMAGEM:
             <br></br><i>Banner Superior Portal: prefira imagens entre 1600 x 700 pixel jpg</i>
             <br></br><i>Banner Aplicativo: prefira imagens entre xx x xx pixel</i>
            </label>

          <div>
            <div>
            <select value={selecionalocal} onChange={handleChangelocal}>
              <option value="bannersup1">Banner Superior 1</option>
              <option value="bannersup2">Banner Superior 2</option>
              <option value="bannersup3">Banner Superior 3</option>
              <option value="bannersup4">Banner Superior 4</option>
              <option value="bannerapp1inicial">Banner App Inicial 1</option>
              <option value="bannerapp2inicial">Banner App Inicial 2</option>
            </select>
            </div>
            <div>
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>
              <input type="file" accept="image/*" onChange={handleFile}  /><br/>
            </div>
          </div>

          <div>
            { imageurl == null ? 
                <img src={avatar} width="250" height="250" alt="Sem Foto" />
                :
                <img src={imageurl} width="250" height="250"/>
              }
          </div>
          <a onClick={ () => handleUpload() }><FiPlusCircle size={40} />ENVIAR</a>

      </form>
      </div>

    </div>

    </div>
  )
}