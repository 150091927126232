
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import avatar from '../imagens/img.png';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './caminhos.css';
import { FiPlusCircle, FiUpload } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [loadCaminhos, setLoadCaminhos] = useState(true);
  const [caminhos, setCaminhos] = useState([]);
  const [caminhosSelected, setCaminhosSelected] = useState(0);

  const [nomecaminho, setNomecaminho] = useState('');
  const [cidadescaminho, setCidadescaminho] = useState('');
  const [estadocaminho, setEstadocaminho] = useState('');
  const [textosimplescaminho, setTextosimplescaminho] = useState('');
  const [textocompletocaminho, setTextocompletocaminho] = useState('');

  const [dadoskmcaminho, setDadoskmcaminho] = useState('');
  const [dadosdiascaminho, setDadosdiascaminho] = useState('');
  const [dadosnivelcaminho, setDadosnivelcaminho] = useState('');
  const [dadosdicascaminho, setDadosdicascaminho] = useState('');

  const [midiavideocaminho, setMidiavideocaminho] = useState('');
  const [midiagpxkmlcaminho, setMidiagpxkmlcaminho] = useState('');

  const [linkutil1caminho, setLinkutil1caminho] = useState('');
  const [linkutil2caminho, setLinkutil2caminho] = useState('');
  const [linkutil3caminho, setLinkutil3caminho] = useState('');

  const [latitudecaminho, setLatitudecaminho] = useState('');
  const [longitudecaminho, setLongitudecaminho] = useState('');
  const [latitudeDeltacaminho, setLatitudeDeltacaminho] = useState('');
  const [longitudeDeltacaminho, setLongitudeDeltacaminho] = useState('');

  const [statuscaminho, setStatuscaminho] = useState('');

  const [idCaminhos, setIdCaminhos] = useState(false);

  const [avatarUrlcaminho, setAvatarUrlcaminho] = useState(null);
  const [avatarUrlcaminhor, setAvatarUrlcaminhor] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);

  const [uploading, setUploading] = useState(false);

  useEffect(()=> {
    async function loadCaminhos(){
      await firebase.firestore().collection('caminhos')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomecaminho: doc.data().nomecaminho
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setCaminhos([ { id: '1', nomecaminho: 'AVULSO' } ]);
          setLoadCaminhos(false);
          return;
        }

        setCaminhos(lista);
        setLoadCaminhos(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadCaminhos(false);
        setCaminhos([ { id: '1', nomecaminho: '' } ]);
      })

    }
    loadCaminhos();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('caminhos').doc(id)
    .get()
    .then((snapshot) => {
      setAvatarUrlcaminho(snapshot.data().avatarUrlcaminho);
      setNomecaminho(snapshot.data().nomecaminho);
      setCidadescaminho(snapshot.data().cidadescaminho);
      setEstadocaminho(snapshot.data().estadocaminho);
      setTextosimplescaminho(snapshot.data().textosimplescaminho);
      setTextocompletocaminho(snapshot.data().textocompletocaminho);
      setDadoskmcaminho(snapshot.data().dadoskmcaminho);
      setDadosdiascaminho(snapshot.data().dadosnivelcaminho);
      setDadosdicascaminho(snapshot.data().dadosdicascaminho);
      setDadosnivelcaminho(snapshot.data().dadosnivelcaminho);
      setMidiavideocaminho(snapshot.data().midiavideocaminho);
      setMidiagpxkmlcaminho(snapshot.data().midiagpxkmlcaminho);
      setLinkutil1caminho(snapshot.data().linkutil1caminho);
      setLinkutil2caminho(snapshot.data().linkutil2caminho);
      setLinkutil3caminho(snapshot.data().linkutil3caminho);
      setLatitudecaminho(snapshot.data().latitudecaminho);
      setLongitudecaminho(snapshot.data().longitudecaminho);
      setLatitudeDeltacaminho(snapshot.data().latitudeDeltacaminho);
      setLongitudeDeltacaminho(snapshot.data().longitudeDeltacaminho);
      setStatuscaminho(snapshot.data().statuscaminho)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setCaminhosSelected(index);
      setIdCaminhos(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdCaminhos(false);
    })
  }

  function handleFile(e){
    if(e.target.files[0]){
      const image = e.target.files[0];
      
      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrlcaminho(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageAvatar(null);
        return null;
      }
    }
  }

  async function handleUpload(){
    toast.success('Envio Iniciado!');
    if (imageAvatar){
    const uploadTask = await firebase.storage()
    .ref(`images/caminhos/${id}/avatar/Avatar`)
    .put(imageAvatar)
    .then( async () => {
      toast.success('Envio Completo!');
      await firebase.storage().ref(`images/caminhos/${id}/avatar/`)
      .child("Avatar").getDownloadURL()
      .then( async (url)=>{
        let urlFoto = url;
        toast.success(`enviou corrreto ${urlFoto}`);
        
        await firebase.firestore().collection('caminhos')
        .doc(id)
        .update({
          avatarUrlcaminho: urlFoto,
          nomecaminho: nomecaminho,
          cidadescaminho: cidadescaminho,
          estadocaminho: estadocaminho,
          textosimplescaminho: textosimplescaminho,
          textocompletocaminho: textocompletocaminho,
          dadoskmcaminho: dadoskmcaminho,
          dadosdiascaminho: dadosdiascaminho,
          dadosnivelcaminho: dadosnivelcaminho,
          dadosdicascaminho: dadosdicascaminho,
          midiavideocaminho: midiavideocaminho,
          midiagpxkmlcaminho: midiagpxkmlcaminho,
          linkutil1caminho: linkutil1caminho,
          linkutil2caminho: linkutil2caminho,
          linkutil3caminho: linkutil3caminho,
          latitudecaminho: latitudecaminho,
          longitudecaminho: longitudecaminho,
          latitudeDeltacaminho: latitudeDeltacaminho,
          longitudeDeltacaminho: longitudeDeltacaminho,
          statuscaminho: statuscaminho,
          userId: user.uid
        })
        .then(()=>{
          toast.success(`Foto Enviada e Caminho editado com sucesso! ${id}`);
          setCaminhosSelected(0);
          setNomecaminho('');
          history.push('/caminhoslistar');
        })
      })
    })
      }
      else {
      alert('Upload failed, sorry :( 39');
    } 
  };


  async function handleRegister(e){
    e.preventDefault();

    if(idCaminhos === true && imageAvatar === null){
      await firebase.firestore().collection('caminhos')
      .doc(id)
      .update({
        nomecaminho: nomecaminho,
        cidadescaminho: cidadescaminho,
        estadocaminho: estadocaminho,
        textosimplescaminho: textosimplescaminho,
        textocompletocaminho: textocompletocaminho,
        dadoskmcaminho: dadoskmcaminho,
        dadosdiascaminho: dadosdiascaminho,
        dadosnivelcaminho: dadosnivelcaminho,
        dadosdicascaminho: dadosdicascaminho,
        midiavideocaminho: midiavideocaminho,
        midiagpxkmlcaminho: midiagpxkmlcaminho,
        linkutil1caminho: linkutil1caminho,
        linkutil2caminho: linkutil2caminho,
        linkutil3caminho: linkutil3caminho,
        latitudecaminho: latitudecaminho,
        longitudecaminho: longitudecaminho,
        latitudeDeltacaminho: latitudeDeltacaminho,
        longitudeDeltacaminho: longitudeDeltacaminho,
        statuscaminho: statuscaminho,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Caminho editada com sucesso!');
        setCaminhosSelected(0);
        setNomecaminho('');
        history.push('/caminhoslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

    }
    else if (idCaminhos === true && imageAvatar !== null){
      handleUpload();
    }

    else if(idCaminhos === false){

    await firebase.firestore().collection('caminhos')
    .add({
      created: new Date(),
      nomecaminho: nomecaminho,
      cidadescaminho: cidadescaminho,
      estadocaminho: estadocaminho,
      textosimplescaminho: textosimplescaminho,
      textocompletocaminho: textocompletocaminho,
      dadoskmcaminho: dadoskmcaminho,
      dadosdiascaminho: dadosdiascaminho,
      dadosnivelcaminho: dadosnivelcaminho,
      dadosdicascaminho: dadosdicascaminho,
      midiavideocaminho: midiavideocaminho,
      midiagpxkmlcaminho: midiagpxkmlcaminho,
      linkutil1caminho: linkutil1caminho,
      linkutil2caminho: linkutil2caminho,
      linkutil3caminho: linkutil3caminho,
      latitudecaminho: latitudecaminho,
      longitudecaminho: longitudecaminho,
      latitudeDeltacaminho: latitudeDeltacaminho,
      longitudeDeltacaminho: longitudeDeltacaminho,
      statuscaminho: statuscaminho,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Caminho cadastrada com sucesso!');
      setNomecaminho('');
      setCidadescaminho('');
      setEstadocaminho('');
      setTextosimplescaminho('');
      setTextocompletocaminho('');

      setCaminhosSelected(0);
      history.push('/caminhoslistar');
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })
  }

  }


  function handleChangeEstado(e){
    setEstadocaminho(e.target.value);
  }

  function handleChangeStatus(e){
    setStatuscaminho(e.target.value);
  }

  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Nova Caminho">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Caminho</label>
            <input type="text" placeholder="Nome da caminho" value={nomecaminho} onChange={ (e) => setNomecaminho(e.target.value) } />

            <label>Status</label>
            <select value={statuscaminho} onChange={handleChangeStatus}>
              <option value="planejado">Planejado</option>
              <option value="disponivel">Disponivel</option>
            </select>

            <label>Estado</label>
            <select value={estadocaminho} onChange={handleChangeEstado}>
              <option value="MG">Mina Gerais</option>
              <option value="RJ">Rio de Janeiro</option>
            </select>

            <label>Cidades</label>
            <input type="text" placeholder="cidades separadas por virgula" value={cidadescaminho} onChange={ (e) => setCidadescaminho(e.target.value) } />

            { idCaminhos === true ?
            <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>

              <input type="file" accept="image/*" onChange={handleFile}  /><br/>
              { avatarUrlcaminho == null ? 
                <img src={avatar} width="250" height="250" alt="Sem Foto Caminho" />
                :
                <img src={avatarUrlcaminho} width="250" height="250" alt="Foto Caminho" />
              }
            </label>
            :
            <label className="label-avatar"> Salve para colocar foto
              <span>
                <FiUpload color="#000" size={25} />
              </span>
            </label>
          }
            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplescaminho}
              onChange={ (e) => setTextosimplescaminho(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletocaminho}
              onChange={ (e) => setTextocompletocaminho(e.target.value) }
            />

            <br></br>
            <label>Dados do caminho</label>
            <label>Km Total</label>
            <input type="text" placeholder="Km total" value={dadoskmcaminho} onChange={ (e) => setDadoskmcaminho(e.target.value) } />
            <label>Número médio de dias para fazer o percurso:</label>
            <input type="text" placeholder="número de dias" value={dadosdiascaminho} onChange={ (e) => setDadosdiascaminho(e.target.value) } />
            <label>Nível</label>
            <input type="text" placeholder="nível de de 01 a 10" value={dadosnivelcaminho} onChange={ (e) => setDadosnivelcaminho(e.target.value) } />
            <label>Dicas</label>
            <input type="text" placeholder="dicas texto" value={dadosdicascaminho} onChange={ (e) => setDadosdicascaminho(e.target.value) } />

            <br></br>
            <label>Mídia e arquivos</label>  
            <label>Endereço Vídeo Promocional</label>
            <input type="text" placeholder="link Facebook" value={midiavideocaminho} onChange={ (e) => setMidiavideocaminho(e.target.value) } />
            <label>Arquivo GPX - KML</label>
            <input type="text" placeholder="link GPX - KML" value={midiagpxkmlcaminho} onChange={ (e) => setMidiagpxkmlcaminho(e.target.value) } />
            <br></br>
            <br></br>

            <label>Links</label>  
            <label>Link útil 01</label>
            <input type="text" placeholder="link util 01" value={linkutil1caminho} onChange={ (e) => setLinkutil1caminho(e.target.value) } />
            <label>Link útil 02</label>
            <input type="text" placeholder="link util 02" value={linkutil2caminho} onChange={ (e) => setLinkutil2caminho(e.target.value) } />
            <label>Link útil 03</label>
            <input type="text" placeholder="link util 03" value={linkutil3caminho} onChange={ (e) => setLinkutil3caminho(e.target.value) } />

            <label>Setar Inicial Region KML</label>
            <input type="text" placeholder="latitude" value={latitudecaminho} onChange={ (e) => setLatitudecaminho(e.target.value) } />
            <input type="text" placeholder="longitude" value={longitudecaminho} onChange={ (e) => setLongitudecaminho(e.target.value) } />
            <input type="text" placeholder="latitudeDelta: 1.3" value={latitudeDeltacaminho} onChange={ (e) => setLatitudeDeltacaminho(e.target.value) } />
            <input type="text" placeholder="longitudeDelta: 1.3" value={longitudeDeltacaminho} onChange={ (e) => setLongitudeDeltacaminho(e.target.value) } />

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}