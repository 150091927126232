
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './projetos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadTrabalhos, setLoadTrabalhos] = useState(true);
  const [trabalhos, setTrabalhos] = useState([]);
  const [trabalhosSelected, setTrabalhosSelected] = useState(0);

  const [nometrabalho, setNometrabalho] = useState('');
  const [textosimplestrabalho, setTextosimplestrabalho] = useState('');
  const [textocompletotrabalho, setTextocompletotrabalho] = useState('');

  const [idtrabalhos, setIdtrabalhos] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadTrabalhos(){
      await firebase.firestore().collection('trabalhos')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nometrabalho: doc.data().nometrabalho
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA TRABALHO ENCONTRADO');
          setTrabalhos([ { id: '1', nometrabalho: 'AVULSO' } ]);
          setLoadTrabalhos(false);
          return;
        }

        setTrabalhos(lista);
        setLoadTrabalhos(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadTrabalhos(false);
        setTrabalhos([ { id: '1', nometreabalho: '' } ]);
      })

    }
    loadTrabalhos();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('trabalhos').doc(id)
    .get()
    .then((snapshot) => {
      setNometrabalho(snapshot.data().nometrabalho);
      setTextosimplestrabalho(snapshot.data().textosimplestrabalho);
      setTextocompletotrabalho(snapshot.data().textocompletotrabalho);

      let index = lista.findIndex(item => item.id === snapshot.data().trabalhoId );
      setTrabalhosSelected(index);
      setIdtrabalhos(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdtrabalhos(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idtrabalhos){
      await firebase.firestore().collection('trabalhos')
      .doc(id)
      .update({
        nometrabalho: nometrabalho,
        textosimplestrabalho: textosimplestrabalho,
        textocompletotrabalho: textocompletotrabalho,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Trabalho editado com sucesso!');
        setTrabalhosSelected(0);
        setNometrabalho('');
        history.push('/trabalhoslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('trabalhos')
    .add({
      created: new Date(),
      nometrabalho: nometrabalho,
      textosimplestrabalho: textosimplestrabalho,
      textocompletotrabalho: textocompletotrabalho,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Trabalho cadastrado com sucesso!');
      setNometrabalho('');
      setTextosimplestrabalho('');
      setTextocompletotrabalho('');
      setTrabalhosSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Trabalhos">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Trabalhos desenvolvidos</label>
            <input type="text" placeholder="Nome do trabalho" value={nometrabalho} onChange={ (e) => setNometrabalho(e.target.value) } />

            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplestrabalho}
              onChange={ (e) => setTextosimplestrabalho(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletotrabalho}
              onChange={ (e) => setTextocompletotrabalho(e.target.value) }
            />
            
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}