import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let firebaseConfig = {
  apiKey: "AIzaSyBh9rQpsaS_JJSB_OHInC6ScS6MQ9FwRYo",
  authDomain: "caminhos-br.firebaseapp.com",
  projectId: "caminhos-br",
  storageBucket: "caminhos-br.appspot.com",
  messagingSenderId: "698508156717",
  appId: "1:698508156717:web:c1b00a00d26202e0927da4",
  measurementId: "G-H9ENWVX70B"
};

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export default firebase;