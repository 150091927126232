
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './rotas.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadcaminhos, setLoadcaminhos] = useState(true);
  const [caminhos, setCaminhos] = useState([]);
  const [caminhosSelected, setCaminhosSelected] = useState(0);

  const [nomerota, setNomerota] = useState('');
  const [caminhorota, setCaminhorota] = useState('');
  const [categoriarota, setCategoriarota] = useState('');
  const [textosimplesrota, setTextosimplesrota] = useState('');
  const [iniciorota, setIniciorota] = useState('');
  const [finalrota, setFinalrota] = useState('');
  const [kmrota, setKmrota] = useState('');
  const [ascendrota, setAscendrota] = useState('');
  const [descendrota, setDescendrota] = useState('');
  const [pontoaltorota, setPontoaltorota] = useState('');
  const [nivelrota, setNivelrota] = useState('');
  const [dicasrota, setDicasrota] = useState('');
  const [gpxkmlrota, setGpxkmlrota] = useState('');
  const [etaparota, setEtaparota] = useState('');

  const [latituderota, setLatituderota] = useState('');
  const [longituderota, setLongituderota] = useState('');
  const [latitudeDeltarota, setLatitudeDeltarota] = useState('');
  const [longitudeDeltarota, setLongitudeDeltarota] = useState('');

  const [idcaminhos, setIdcaminhos] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadcaminhos(){
      await firebase.firestore().collection('caminhos')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomecaminho: doc.data().nomecaminho
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setCaminhos([ { id: '1', nomecaminho: 'AVULSO' } ]);
          setLoadcaminhos(false);
          return;
        }

        setCaminhos(lista);
        setLoadcaminhos(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadcaminhos(false);
        setCaminhos([ { id: '1', nomecaminho: '' } ]);
      })

    }
    loadcaminhos();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('rotas').doc(id)
    .get()
    .then((snapshot) => {
      setNomerota(snapshot.data().nomerota);
      setCaminhorota(snapshot.data().caminhorota);
      setCategoriarota(snapshot.data().categoriarota);
      setTextosimplesrota(snapshot.data().textosimplesrota);
      setIniciorota(snapshot.data().iniciorota);
      setFinalrota(snapshot.data().finalrota);
      setKmrota(snapshot.data().kmrota);
      setAscendrota(snapshot.data().ascendrota);
      setDescendrota(snapshot.data().descendrota);
      setPontoaltorota(snapshot.data().pontoaltorota);
      setNivelrota(snapshot.data().nivelrota);
      setDicasrota(snapshot.data().dicasrota);
      setGpxkmlrota(snapshot.data().gpxkmlrota);
      setEtaparota(snapshot.data().etaparota);
      setLatituderota(snapshot.data().latituderota);
      setLongituderota(snapshot.data().longituderota);
      setLatitudeDeltarota(snapshot.data().latitudeDeltarota);
      setLongitudeDeltarota(snapshot.data().longitudeDeltarota);

      let index = lista.findIndex(item => item.id === snapshot.data().caminhoId );
      setCaminhosSelected(index);
      setIdcaminhos(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdcaminhos(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(id){
      await firebase.firestore().collection('rotas')
      .doc(id)
      .update({
        nomerota: nomerota,
        caminhorota: caminhos[caminhosSelected].nomecaminho,
        caminhoId: caminhos[caminhosSelected].id,
        categoriarota: categoriarota,
        textosimplesrota: textosimplesrota,
        iniciorota: iniciorota,
        finalrota: finalrota,
        kmrota: kmrota,
        ascendrota: ascendrota,
        descendrota: descendrota,
        pontoaltorota: pontoaltorota,
        nivelrota: nivelrota,
        dicasrota: dicasrota,
        gpxkmlrota: gpxkmlrota,
        etaparota: etaparota,
        latituderota: latituderota,
        longituderota: longituderota,
        latitudeDeltarota: latitudeDeltarota,
        longitudeDeltarota: longitudeDeltarota,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Ponto editado com sucesso!');
        setCaminhosSelected(0);
        setNomerota('');
        setCaminhorota('');
        setCategoriarota('');
        setTextosimplesrota('');

        setCaminhosSelected(0);
        history.push('/rotaslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('rotas')
    .add({
      created: new Date(),
      nomerota: nomerota,
      caminhorota: caminhorota,
      categoriarota: categoriarota,
      textosimplesrota: textosimplesrota,
      iniciorota: iniciorota,
      finalrota: finalrota,
      kmrota: kmrota,
      ascendrota: ascendrota,
      descendrota: descendrota,
      pontoaltorota: pontoaltorota,
      nivelrota: nivelrota,
      dicasrota: dicasrota,
      gpxkmlrota: gpxkmlrota,
      etaparota: etaparota,
      latituderota: latituderota,
      longituderota: longituderota,
      latitudeDeltarota: latitudeDeltarota,
      longitudeDeltarota: longitudeDeltarota,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Rota cadastrada com sucesso!');
      setNomerota('');
      setCaminhorota('');
      setCategoriarota('');
      setTextosimplesrota('');

      
      setCaminhosSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }

 //Chamado quando troca a cidade
  function handleChangeCaminho(e){
    setCaminhosSelected(e.target.value);
  }


  //Chamado quando troca a categoria
  function handleChangeCategoria(e){
    setCategoriarota(e.target.value);
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Nova Rota">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >


            <label>Nome</label>
            <input type="text" placeholder="Nome do rota" value={nomerota} onChange={ (e) => setNomerota(e.target.value) } />

            <label>Caminho</label>
            {loadcaminhos ? (
              <input type="text" disabled={true} value="Carregando caminhos..." />
            ) : (
                <select value={caminhosSelected} onChange={handleChangeCaminho} >
                {caminhos.map((item, index) => {
                  return(
                    <option key={item.id} value={index} >
                      {item.nomecaminho}
                    </option>
                  )
                })}
              </select>
            )}

            <label>Etapa</label>
            <input type="text" placeholder="número etapa" value={etaparota} onChange={ (e) => setEtaparota(e.target.value) } />

            <label>Categoria</label>
            <select value={categoriarota} onChange={handleChangeCategoria}>
              <option value="Pe">A Pé</option>
              <option value="Bicicleta">Bicicleta</option>
              <option value="Carro">Carro</option>
            </select>


            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesrota}
              onChange={ (e) => setTextosimplesrota(e.target.value) }
            />

            <label>Dicas</label>
            <input type="text" placeholder="Dicas" value={dicasrota} onChange={ (e) => setDicasrota(e.target.value) } />

            <label>Inicio Rota</label>
            <input type="text" placeholder="Cidade" value={iniciorota} onChange={ (e) => setIniciorota(e.target.value) } />

            <label>Final Rota</label>
            <input type="text" placeholder="Cidade" value={finalrota} onChange={ (e) => setFinalrota(e.target.value) } />

            <label>Km</label>
            <input type="text" placeholder="Km" value={kmrota} onChange={ (e) => setKmrota(e.target.value) } />

            <label>Ascendência</label>
            <input type="text" placeholder="xx metros" value={ascendrota} onChange={ (e) => setAscendrota(e.target.value) } />

            <label>Descendência</label>
            <input type="text" placeholder="xx metros" value={descendrota} onChange={ (e) => setDescendrota(e.target.value) } />

            <label>Ponto mais alto</label>
            <input type="text" placeholder="xx metros" value={pontoaltorota} onChange={ (e) => setPontoaltorota(e.target.value) } />

            <label>Nivel</label>
            <input type="text" placeholder="Nível Percurso" value={nivelrota} onChange={ (e) => setNivelrota(e.target.value) } />

            <label>KML - conversor GPX-KML: https://www.gpsvisualizer.com/map_input?form=googleearth</label>
            <input type="text" placeholder="Link KML" value={gpxkmlrota} onChange={ (e) => setGpxkmlrota(e.target.value) } />

            <label>Setar Inicial Region KML</label>
            <input type="text" placeholder="latitude" value={latituderota} onChange={ (e) => setLatituderota(e.target.value) } />
            <input type="text" placeholder="longitude" value={longituderota} onChange={ (e) => setLongituderota(e.target.value) } />
            <input type="text" placeholder="latitudeDelta: 1.3" value={latitudeDeltarota} onChange={ (e) => setLatitudeDeltarota(e.target.value) } />
            <input type="text" placeholder="longitudeDelta: 1.3" value={longitudeDeltarota} onChange={ (e) => setLongitudeDeltarota(e.target.value) } />
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}