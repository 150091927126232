
import './site.css';
import { useState, useEffect } from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiSettings } from 'react-icons/fi';
import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import YouTube from 'react-youtube';

import firebase from '../../services/firebaseConnection';
import Modalcidade from '../../components/Modalcidade';

import logo from '../imagens/logo.png';
import apple from '../imagens/bt_apple2.png';
import google from '../imagens/bt_google2.png';
import img from '../imagens/img.png';

const listRef = firebase.firestore().collection('cidades').orderBy('nomecidade', 'asc');
const listRef2 = firebase.firestore().collection('trabalhos').orderBy('nometrabalho', 'asc');
const listRef3 = firebase.firestore().collection('projetos').orderBy('created', 'asc');
const listRef4 = firebase.firestore().collection('caminhos').orderBy('nomecaminho', 'asc');

const opts = {
  //height: '360',
  //width: '100%',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    // https://developers.google.com/youtube/player_parameters?hl=pt-BR
    autoplay: 1,
    loop: 1,
    controls: 0,
    modestbranding: 1,
    //allowfullscreen,
    rel: 0,
    iv_load_policy: 3,
    enablejsapi: 1,
  },
};

export default function Dashboard(){
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();
 
  const [loading2, setLoading2] = useState(true);
  const [loadingMore2, setLoadingMore2] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);
  const [lastDocs2, setLastDocs2] = useState();

  const [loading3, setLoading3] = useState(true);
  const [loadingMore3, setLoadingMore3] = useState(false);
  const [isEmpty3, setIsEmpty3] = useState(false);
  const [lastDocs3, setLastDocs3] = useState();

  const [loading4, setLoading4] = useState(true);
  const [loadingMore4, setLoadingMore4] = useState(false);
  const [isEmpty4, setIsEmpty4] = useState(false);
  const [lastDocs4, setLastDocs4] = useState();

  const [complemento, setComplemento] = useState();
  const [texto1, setTexto1] = useState();
  const [texto2, setTexto2] = useState();
  const [texto3, setTexto3] = useState();
  const [texto4, setTexto4] = useState();
  const [texto5, setTexto5] = useState();
  const [texto6, setTexto6] = useState();
  const [linkvideo, setLinkvideo] = useState();
  
  const [nomeconfig, setNomeconfig] = useState('');
  const [sloganconfig, setSloganconfig]= useState('');
  const [desccurtaconfig, setDesccurtaconfig] = useState('');
  const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
  const [razaoconfig, setRazaoconfig] = useState('');
  const [cnpjconfig, setCnpjconfig] = useState('');
  const [cepconfig, setCepconfig] = useState('');
  const [logradouroconfig, setLogradouroconfig] = useState('');
  const [numeroconfig, setNumeroconfig] = useState('');
  const [complementoconfig, setComplementoconfig] = useState('');
  const [bairroconfig, setBairroconfig] = useState('');
  const [cidadeconfig, setCidadeconfig] = useState('');
  const [estadoconfig, setEstadoconfig] = useState('');
  const [paisconfig, setPaisconfig] = useState('');
  const [siteconfig, setSiteconfig] = useState('');
  const [emailconfig, setEmailconfig] = useState('');
  const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
  const [linkfbconfig, setLinkfbconfig] = useState('');
  const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
  const [linkdriveconfig, setLinkdriveconfig] = useState('');
  const [linklinkedconfig, setLinklinkedconfig] = useState('');
  const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
  const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
  const [telefone1config, setTelefone1config] = useState('');
  const [telefone2config, setTelefone2config] = useState('');
  const [whats1config, setWhats1config] = useState('');
  const [whats2config, setWhats2config] = useState('');

  const [cidades, setCidades] = useState([]);
  const [trabalhos, setTrabalhos] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [caminhos, setCaminhos] = useState([]);
  
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(()=> {

    async function loadTextos(){
      await firebase.firestore().collection('textos')
      .doc('e9J5BeOmitb5Z7bE6Oh1')
      .get()
      .then((snapshot) => {
        setComplemento(snapshot.data().complemento);  
        setTexto1(snapshot.data().texto1);
        setTexto2(snapshot.data().texto2);
        setTexto3(snapshot.data().texto3);
        setTexto4(snapshot.data().texto4);
        setTexto5(snapshot.data().texto5);
        setTexto6(snapshot.data().texto6);
        setLinkvideo(snapshot.data().linkvideo)
      })

    }
    loadTextos();

    async function loadConfig(){
      await firebase.firestore().collection('config')
      .doc('WOaXsnLkAIGJfGCgposp')
      .get()
      .then((snapshot) => {
        setNomeconfig(snapshot.data().nomeconfig);
        setSloganconfig(snapshot.data().sloganconfig);
        setDesccurtaconfig(snapshot.data().desccurtaconfig);
        setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
        setRazaoconfig(snapshot.data().razaoconfig);
        setCnpjconfig(snapshot.data().cnpjconfig);
        setCepconfig(snapshot.data().cepconfig);
        setLogradouroconfig(snapshot.data().logradouroconfig);
        setNumeroconfig(snapshot.data().numeroconfig);
        setComplementoconfig(snapshot.data().complementoconfig);
        setBairroconfig(snapshot.data().bairroconfig);
        setCidadeconfig(snapshot.data().cidadeconfig);
        setEstadoconfig(snapshot.data().estadoconfig);
        setPaisconfig(snapshot.data().paisconfig);
        setSiteconfig(snapshot.data().siteconfig);
        setEmailconfig(snapshot.data().emailconfig);
        setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
        setLinkfbconfig(snapshot.data().linkfbconfig);
        setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
        setLinkdriveconfig(snapshot.data().linkdriveconfig);
        setLinklinkedconfig(snapshot.data().linklinkedconfig);
        setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
        setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
        setTelefone1config(snapshot.data().telefone1config);
        setTelefone2config(snapshot.data().telefone2config);
        setWhats1config(snapshot.data().whats1config);
        setWhats2config(snapshot.data().whats2config)
      })
    }
    loadConfig();

    async function loadCidades(){
      await listRef
      .get()
      .then((snapshot) => {
        updateState(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore(false);
      })
  
      setLoading(false);
    }
    loadCidades();

    async function loadTrabalhos(){
      await listRef2.limit(10)
      .get()
      .then((snapshot) => {
        updateStatetrabalhos(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore2(false);
      })
  
      setLoading2(false);
    }
    loadTrabalhos();

    async function loadProjetos(){
      await listRef3.limit(5)
      .get()
      .then((snapshot) => {
        updateStateprojeto(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore3(false);
      })
  
      setLoading3(false);
  
    }

    loadProjetos();

    async function loadCaminhos(){
      await listRef4.limit(10)
      .get()
      .then((snapshot) => {
        updateStatecaminhos(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore4(false);
      })
  
      setLoading4(false);
  
    }

    loadCaminhos();

    return () => {

    }
  }, []);

  async function updateState(snapshot){
    const isCollectionEmpty = snapshot.size === 0;

    if(!isCollectionEmpty){
      let lista = [];

      snapshot.forEach((doc)=>{
        lista.push({
          id: doc.id,
          nomecidade: doc.data().nomecidade,
          estadocidade: doc.data().estadocidade,
          textosimplescidade: doc.data().textosimplescidade,
          textocompletocidade: doc.data().textocompletocidade,
          linkfbcidade: doc.data().linkfbcidade,
          linkinstagramcidade: doc.data().linkinstagramcidade,
          linksitecidade: doc.data().linksitecidade,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setCidades(cidades => [...cidades, ...lista]);
      setLastDocs(lastDoc);

    }else{
      setIsEmpty(true);
    }

    setLoadingMore(false);

  }

  async function updateStatetrabalhos(snapshot){
    const isCollectionEmpty2 = snapshot.size === 0;

    if(!isCollectionEmpty2){
      let lista2 = [];

      snapshot.forEach((doc)=>{
        lista2.push({
          id2: doc.id,
          nometrabalho: doc.data().nometrabalho,
          textosimplestrabalho: doc.data().textosimplestrabalho,
          textocompletotrabalho: doc.data().textocompletotrabalho,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc2 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setTrabalhos(trabalhos => [...trabalhos, ...lista2]);
      setLastDocs2(lastDoc2);

    }else{
      setIsEmpty2(true);
    }

    setLoadingMore2(false);

  }


  async function updateStateprojeto(snapshot){
    const isCollectionEmpty3 = snapshot.size === 0;

    if(!isCollectionEmpty3){
      let lista3 = [];

      snapshot.forEach((doc)=>{
        lista3.push({
          id3: doc.id,
          nomeprojeto: doc.data().nomeprojeto,
          estadoprojeto: doc.data().estadoprojeto,
          textosimplesprojeto: doc.data().textosimplesprojeto,
          textocompletoprojeto: doc.data().textocompletoprojeto,
          linkfbprojeto: doc.data().linkfbprojeto,
          linkinstagramprojeto: doc.data().linkinstagramprojeto,
          linksiteprojeto: doc.data().linksiteprojeto,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc3 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setProjetos(projetos => [...projetos, ...lista3]);
      setLastDocs(lastDoc3);

    }else{
      setIsEmpty3(true);
    }

    setLoadingMore3(false);
  }

  async function updateStatecaminhos(snapshot){
    const isCollectionEmpty4 = snapshot.size === 0;

    if(!isCollectionEmpty4){
      let lista4 = [];

      snapshot.forEach((doc)=>{
        lista4.push({
          id4: doc.id,
          avatarUrlcaminho: doc.data().avatarUrlcaminho,
          nomecaminho: doc.data().nomecaminho,
          cidadescaminho: doc.data().cidadescaminho,
          estadocaminho: doc.data().estadocaminho,
          textosimplescaminho: doc.data().textosimplescaminho,
          textocompletocaminho: doc.data().textocompletocaminho,
          dadoskmcaminho: doc.data().dadoskmcaminho,
          dadosdiascaminho: doc.data().dadosdiascaminho,
          dadosnivelcaminho: doc.data().dadosnivelcaminho,
          dadosdicascaminho: doc.data().dadosdicascaminho,
          midiavideocaminho: doc.data().midiavideocaminho,
          midiagpxkmlcaminho: doc.data().midiagpxkmlcaminho,
          linkutil1caminho: doc.data().linkutil1caminho,
          linkutil2caminho: doc.data().linkutil2caminho,
          linkutil3caminho: doc.data().linkutil3caminho,
          statuscaminho: doc.data().statuscaminho,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc4 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setCaminhos(caminhos => [...caminhos, ...lista4]);
      setLastDocs4(lastDoc4);

    }else{
      setIsEmpty4(true);
    }

    setLoadingMore4(false);
  }


  function togglePostModal(item){
    setShowPostModal(!showPostModal) //trocando de true pra false
    setDetail(item);
  }

  function onReady(event) {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  }
      //<div className="TopoVideo">.
      //<YouTube videoId={linkvideo} opts={opts} onReady={onReady} />
      //</div>
    return(


      <div>

        <div id="posiciona"> 
        <img src={logo} className="App-logo"  alt="Caminhos - Expedição Brasil" />
            <br></br> 
            <a href={linkapplestoreconfig} target="_blank"><img src={apple} className="App-baixar"  alt="Trilha dos Inconfidentes" /></a>
            <a href={linkgoogleplayconfig} target="_blank"><img src={google} className="App-baixar"  alt="Trilha dos Inconfidentes" /></a>
        </div>

      <div class="video-container">
          <iframe class="video" src="https://www.youtube.com/embed/250SrBSXaBc?autoplay=1&mute=1&loop=1
              &controls=0
              &modestbranding=1
              &rel=0" name="" allow="autoplay; loop; controls; modestbranding; rel; encrypted-media" allowfullscreen></iframe>
      </div>

  
      <div className="Caminhos" id="Caminhos">
        Estamos em atualização... em breve muitas novidades..
      </div>

      <div className="Espaco">
      </div>
   
      <div className="Tonatrilha">
      Todos os direitos reservados © {razaoconfig}, {cnpjconfig} <Link to="/login"><FiSettings color="#000" size={16} /></Link>    
      </div>
      {showPostModal && (
        <Modalcidade
          conteudo={detail}
          close={togglePostModal}
        />
      )}
  </div>
  )
}