
import './modal.css';

import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { FiX } from 'react-icons/fi';


export default function Modal({conteudo, close}){
  return(
    <div className="modal">
      <div className="container">
        <button className="close" onClick={ close }>
          <FiX size={23} color="#FFF" />
          Fechar
        </button>

        <div>
          <h2>{conteudo.nomecidade}</h2>

          <div className="row">
            <span>
              {conteudo.textosimplescidade}
            </span>
          </div>

          <div className="row">
            <span>
               <i>{conteudo.textocompletocidade}</i>
            </span>
          </div>
          <div className="row">
          <h3>Links:</h3>
            <span>
                <a href={conteudo.linkfbcidade} target="_blank"><FaFacebookSquare /></a>&nbsp;&nbsp;
                <a href={conteudo.linkinstagramcidade} target="_blank"><FaInstagram /></a>&nbsp;&nbsp;
                <a href={conteudo.linksitecidade} target="_blank"><FaLaptop /></a>&nbsp;&nbsp;
            </span>
          </div>

        </div>
      </div>
    </div>
  )
}